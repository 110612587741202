<template>
    <card style="overflow-y: auto">
        <template v-slot:header>
            <div class="group-wrapper">
                <h3>{{ value.name }}</h3>
                <div class="group-type">
                    <label>Type</label>
                    <select class="form-control" :value="groupType" @change="changeType">
                        <option value="allday">All day</option>
                        <option value="timeslot">Timeslot</option>
                        <option value="opendate">Open date</option>
                    </select>
                    <base-button @click="selectTicketType()" size="sm" type="neutral">
                        Tickets
                    </base-button>
                </div>
            </div>
        </template>
        <table class="table">
            <thead>
                <tr>
                    <th>
                        <div class="timeHead">Time</div>
                    </th>
                    <th v-for="ticket in tickets.filter(
                        (t) => selectedTickets.indexOf(t.id) > -1
                    )" :key="'ticket' + ticket.id">
                        {{ ticket.name.it }}
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(timeSlotGroup, key) in groupTickets" :key="'time-slot' + key">
                    <td>
                        <div v-if="groupType === 'timeslot'" class="timeShow">
                            {{ key }}
                        </div>
                        <div v-if="groupType === 'allday'" class="timeShow">All day</div>
                        <div v-if="groupType === 'opendate'" class="timeShow">
                            Open date
                        </div>
                    </td>
                    <td v-for="ticket in tickets.filter(
                        (t) => selectedTickets.indexOf(t.id) > -1
                    )" :key="'ticket' + ticket.id">
                        <input v-if="groupType != 'opendate'" v-model="timeSlotGroup[ticket.id].qty" type="number" class="form-control"
                            style="max-width: 70px" />
                        <span v-if="groupType === 'opendate'">AVAILABLE</span>
                    </td>
                    <td>
                        <button v-if="groupType === 'timeslot'" @click="removeTimeSlot(key)"
                            class="btn btn-sm btn-danger">
                            <i class="ni ni-fat-remove"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <template v-slot:footer>
            <div class="options-wrapper">
                <div class="add-time-wrapper" v-if="groupType === 'timeslot'">
                    <input v-model="modelTimeSlot" type="time" class="form-control"
                        style="width: 100px; margin-right: 10px" />
                    <button @click="addTimeSlot(modelTimeSlot)" class="btn btn-sm btn-primary">
                        Add time
                    </button>
                </div>
                <div class="add-title-wrapper" v-if="groupType === 'allday'">
                    <div>
                        <label>Title It</label>
                        <input v-model="allDayName.it" type="text" class="form-control"
                            style="width: 180px; margin-right: 10px" />
                    </div>
                    <div>
                        <label>Title En</label>
                        <input v-model="allDayName.en" type="text" class="form-control"
                            style="width: 180px; margin-right: 10px" />
                    </div>
                </div>

                <div class="add-title-wrapper" v-if="groupType === 'opendate'"></div>

                <div class="action-buttons">
                    <button @click="save" class="btn btn-sm btn-success">Save</button>
                    <button @click="deleteGroup(value.id)" class="btn btn-sm btn-danger">
                        Delete
                    </button>
                </div>
            </div>
        </template>
    </card>

    <modal v-model:show="ticketsSelection">
        <template v-slot:header>
            <h5 class="modal-title">{{ value.name }} tickets selection</h5>
        </template>
        <div class="tickets-selection__content">
            <label class="form-control ticket-type-checkbox" v-for="ticket in tickets" :key="'ticket' + ticket.id">
                <input type="checkbox" :value="ticket.id" v-model="modalSelectedTickets" />
                {{ ticket.name.it }}
            </label>
        </div>
        <template v-slot:footer>
            <div class="action-buttons">
                <button @click="applyTicketsSelection(modalSelectedTickets)"
                    class="btn btn-sm btn-default">Apply</button>
                <button @click="ticketsSelection = false" class="btn btn-sm btn-danger">
                    Cancel
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import Card from "@/components/Cards/Card";
import {
    updateOrCreateGroup,
    removeTicketsFromGroups,
    deleteGroup,
} from "@/api/gardens";
import _ from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import modal from "@/components/Modal";
export default {
    name: "group",
    components: { Card, modal },
    inject: ["runToast"],
    emits: ["groupDeleted"],
    props: {
        groupIndex: Number,
        tickets: Array,
        value: Object,
        garden_id: String,
    },
    data() {
        return {
            groupType: "",
            groupTickets: {},
            modelTimeSlot: "",
            allDayName: { it: "Tutto il giorno", en: "All day" },
            selectedTickets: [],
            modalSelectedTickets: null,
            ticketsSelection: false,
        };
    },
    mounted() {
        this.groupType = this.value.type;
        this.allDayName = { ...this.allDayName, ...this.value.all_day_text };
        const ticketsIds = this.tickets.map((t) => t.id);
        let grouped = _.groupBy(this.value.groupsTickets, (g) => {
            const time = g.start_time.split(":");
            return time[0] + ":" + time[1];
        });

        this.selectedTickets = [...new Set(this.value.groupsTickets?.filter(gt => gt.active === true).map(gt => gt.ticket_id.id) ?? ticketsIds)];
        let final = {};
        for (const [key, value] of Object.entries(this.sortByTime(grouped))) {
            final[key] = _.groupBy(value, (g) => g.ticket_id.id);

            ticketsIds.forEach((t) => {
                if (final[key][t]) {
                    final[key][t] = final[key][t][0];
                } else {
                    final[key][t] = {
                        start_time: key,
                        qty: 10,
                        active: true,
                        group_id: this.value.id,
                        ticket_id: t,
                    };
                }
            });
        }

        this.groupTickets = final;
    },
    methods: {
        sortByTime(grouped) {
            return Object.keys(grouped)
                .sort((a, b) => {
                    return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b);
                })
                .reduce(function (result, key) {
                    result[key] = grouped[key];
                    return result;
                }, []);
        },
        addTimeSlot(time) {
            if (!time) {
                return;
            }
            if (this.groupTickets[time]) {
                return;
            }

            let slots = {};
            this.tickets.forEach((t) => {
                slots[t.id] = {
                    start_time: time,
                    qty: 10,
                    active: this.selectedTickets.indexOf(t.id) > -1,
                    group_id: this.value.id,
                    ticket_id: t.id,
                };
            });
            this.groupTickets[time] = slots;
        },
        async deleteGroup(id) {
            const { value: accept } = await swal.fire({
                title: `Are you sure?`,
                text: "You will delete the group",
                buttonsStyling: false,
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-sm btn-default",
                    cancelButton: "btn btn-sm btn-danger",
                },
                icon: "question",
            });
            if (accept && id) {
                deleteGroup(id).then((res) => {
                    this.$emit("groupDeleted", this.groupIndex);
                    this.runToast("bottom-right", "success", "The group was deleted");
                });
            }
            if (accept && !id) {
                this.$emit("groupDeleted", this.groupIndex);
            }
        },
        async removeTimeSlot(time) {
            const { value: accept } = await swal.fire({
                title: `Are you sure?`,
                text: "You will delete the timeslots",
                buttonsStyling: false,
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-sm btn-default",
                    cancelButton: "btn btn-sm btn-danger",
                },
                icon: "question",
            });
            if (accept) {
                let ticketsGroupIds = [];

                for (const [key, value] of Object.entries(this.groupTickets[time])) {
                    ticketsGroupIds.push(this.groupTickets[time][key].id);
                }

                removeTicketsFromGroups(ticketsGroupIds).then((res) => {
                    delete this.groupTickets[time];
                    this.runToast(
                        "bottom-right",
                        "success",
                        "The timeslots were deleted"
                    );
                });
            }
        },
        async save() {
            let ticketGroups = Object.keys(this.groupTickets).reduce((acc, curr, idx) => {
                Object.keys(this.groupTickets[curr]).forEach(gtk => {
                    if (acc.indexOf(this.groupTickets[curr][gtk].id) === -1) {
                        acc.push(this.groupTickets[curr][gtk].id);
                    }
                });
                return acc;
            }, []);
            let ticketGroupsToDelete = this.value.groupsTickets?.map(gt => gt.id).filter(i => ticketGroups.indexOf(i) === -1);

            if (ticketGroupsToDelete?.length > 0) {
                await removeTicketsFromGroups(ticketGroupsToDelete);
            }

            updateOrCreateGroup(this.garden_id, {
                name: this.value.name,
                type: this.groupType,
                active: this.value.active,
                timeSlots: this.groupTickets,
                id: this.value.id ? this.value.id : null,
                all_day_text: (this.groupType === 'allday') ? this.allDayName : {}
            }).then((res) => {
                this.runToast("bottom-right", "success", "The group was updated");
            });
        },
        async changeType(val) {
            const { value: accept } = await swal.fire({
                title: `Are you sure?`,
                text: "You will reset all timeslots!",
                buttonsStyling: false,
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn btn-sm btn-default",
                    cancelButton: "btn btn-sm btn-danger",
                },
                icon: "question",
            });

            if (accept) {
                this.groupType = val.target.value;
                if (val.target.value === "allday") {
                    let slots = {};
                    this.tickets.forEach((t) => {
                        slots[t.id] = {
                            start_time: "10:00",
                            qty: 10,
                            active: this.selectedTickets.indexOf(t.id) > -1,
                            group_id: this.value.id,
                            ticket_id: t.id,
                        };
                    });
                    this.groupTickets = { "10:00": slots };
                } else if (val.target.value === "opendate") {
                    let slots = {};
                    this.tickets.forEach((t) => {
                        slots[t.id] = {
                            start_time: "00:00",
                            qty: 10,
                            active: this.selectedTickets.indexOf(t.id) > -1,
                            group_id: this.value.id,
                            ticket_id: t.id,
                        };
                    });
                    this.groupTickets = { "opendate": slots };
                }
            }
        },
        selectTicketType() {
            this.modalSelectedTickets = [...this.selectedTickets];
            this.ticketsSelection = true;
        },
        applyTicketsSelection(ticketTypesSelection) {
            Object.keys(this.groupTickets).forEach(k => {
                Object.keys(this.groupTickets[k]).forEach(tk => this.groupTickets[k][tk].active = (ticketTypesSelection.indexOf(Number(tk)) > -1) ? true : false);
            });

            this.ticketsSelection = false;
            this.selectedTickets = [...ticketTypesSelection];
            this.modalSelectedTickets = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.group-type {
    width: 300px;
    justify-content: center;
    display: flex;
    align-items: center;

    label,
    select {
        margin-right: 10px;
    }
}



.group-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timeShow {
    border: 1px solid;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-radius: 10px;
    background: #f2f3f9;
    font-weight: bold;
}

.timeHead {
    width: 100px;
    text-align: center;
    padding: 5px;
    font-weight: bold;
}

.table {

    th,
    td {
        //max-width: 100px;
    }

    th {
        word-wrap: break-word !important;
        white-space: pre-wrap !important;
    }
}

.form-control {
    height: calc(1em + 1rem + 5px);
    padding: 5px;
}

input {
    text-align: center;
}

.add-time-wrapper {
    display: flex;
    max-width: 20%;
    flex-flow: row;
    justify-content: space-around;

    button {
        width: 100px;
    }
}

.add-title-wrapper {
    display: flex;

    flex-flow: row;
    justify-content: space-around;
}

.options-wrapper {
    display: flex;
    justify-content: space-between;

    .action-buttons {
        display: flex;
        justify-content: space-between;
        align-items: end;

        button {
            width: 100px;
            max-height: 40px;
        }
    }
}

.card {
    margin-top: 15px;
}

.ticket-type-checkbox {
    display: block;
    border: none;
}

.tickets-selection__content {
    max-height: 200px;
    overflow-y: auto;
}
</style>
